import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Countdown.module.scss";

const marriageDate = new Date(2022, 2, 26, 19, 30, 0);
const todayDate = new Date();
const totalSeconds = (marriageDate.getTime() - todayDate.getTime()) / 1000;
const daysToMarriage = Math.max(0, Math.floor(totalSeconds / (24 * 3600)));
const hoursToMarriage =Math.max(0, Math.floor((totalSeconds - daysToMarriage * 24 * 3600) / 3600));
const minutes = Math.max(0,Math.floor((totalSeconds - daysToMarriage * 24 * 3600 - hoursToMarriage * 3600) / 60));
const seconds = Math.max(0,Math.floor(totalSeconds - daysToMarriage * 24 * 3600 - hoursToMarriage * 3600 - minutes * 60));

function Countdown() {
  const [[days, hrs, mins, secs], setTime] = useState([daysToMarriage, hoursToMarriage, minutes, seconds]);

  const { t } = useTranslation();

  useEffect(() => {
    if (marriageDate > todayDate) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });

  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) {
      setTime([days - 1, 23, 59, 59]);
    } else if (mins === 0 && secs === 0) {
      setTime([days, hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([days, hrs, mins - 1, 59]);
    } else {
      setTime([days, hrs, mins, secs - 1]);
    }
  };

  return (
    <div className={styles.countdown}>
      <h3>{t("countdown")}</h3>
      <div className={styles.countdownItems}>
        <div>
          <p>{days < 10 ? "0" + days : days}</p>
          <span>{t("days")}</span>
        </div>
        <div>
          <p>{hrs < 10 ? "0" + hrs : hrs}</p>
          <span>{t("hours")}</span>
        </div>
        <div>
          <p>{mins < 10 ? "0" + mins : mins}</p>
          <span>{t("minutes")}</span>
        </div>
        <div>
          <p>{secs < 10 ? "0" + secs : secs}</p>
          <span>{t("seconds")}</span>
        </div>
      </div>
      <div className={styles.letter}>
        <p className={styles.letterV}>V</p>
        <p className={styles.letterE}>e</p>
        <p className={styles.letterD}>D</p>
      </div>
    </div>
  );
}

export default Countdown;
