import React from "react";
import { useTranslation } from "react-i18next";
import Frame from "../frame/Frame";
import styles from "./Marriage.module.scss";

function Marriage() {
  const { t } = useTranslation();
  const { width } = getWindowDimensions();
  const widthMapFrame = Math.min(width * 0.8, 400);

  return (
    <div id="marriage" className={styles.marriage}>
      {/* <Frame color="rose">
        <div className={styles.wherewhen}>
          <div>
            <p className="where">{t("where")}</p>
            {"e"}
            <p className="when">{t("when")}</p>
          </div>
        </div>
      </Frame> */}
      <Frame color="rose2">
        <div className={styles.details}>
          <h1>{t("ceremony")}</h1>
          <h2>{t("date-title")}</h2>
          <h2>{t("time-ceremony-title")}</h2>
          <h3>Igreja Madre de Deus, Cais da Alfândega - Recife, PE, 50030-100, Brazil</h3>
          <iframe title="ceremony-location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.3614424451894!2d-34.87563778461511!3d-8.064565782821186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18a4d411f05d%3A0xe537e84a23c19532!2sIgreja%20da%20Madre%20de%20Deus!5e0!3m2!1spt-BR!2sca!4v1641397650749!5m2!1spt-BR!2sca" width={widthMapFrame} height="450" style={{ border: 0 }} loading="lazy"></iframe>
        </div>
      </Frame>
      <Frame color="rose3">
        <div className={styles.details}>
          <h1>{t("reception")}</h1>
          <h2>{t("date-title")}</h2>
          <h2>{t("time-reception-title")}</h2>
          <h3>Di Branco Recepções, R. do Apolo, 199 - Recife, PE, 50030-220, Brazil</h3>
          <iframe
            title="reception-location"
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1975.189464273402!2d-34.87394589179277!3d-8.062775642852046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e3!4m5!1s0x7ab18a4d411f05d%3A0xe537e84a23c19532!2sIgreja%20da%20Madre%20de%20Deus%20-%20Cais%20da%20Alf%C3%A2ndega%20-%20Recife%2C%20PE%2C%2050030-100%2C%20Brasil!3m2!1d-8.064570999999999!2d-34.8734515!4m5!1s0x7ab18a33c298b73%3A0xb0df80aad7df262a!2sDi%20Branco%20Recep%C3%A7%C3%B5es%20-%20Rua%20do%20Apolo%20-%20Recife%2C%20PE%2C%20Brasil!3m2!1d-8.0609278!2d-34.8721338!5e0!3m2!1spt-BR!2sca!4v1641397859862!5m2!1spt-BR!2sca"
            width={widthMapFrame}
            height="450"
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </Frame>
    </div>
  );
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default Marriage;
