import { Dialog, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import styles from "./Photos.module.scss";
import { useTranslation } from 'react-i18next';

function Photos() {
  const [photos, setPhotos] = useState<{ url: string; id: string; }[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState<{ url: string; id: string; } | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const loadPhotos = async () => {
      // const url = "https://api.onedrive.com/v1.0/drives/4a0b0fb0fad83a44/items/4A0B0FB0FAD83A44!19512/children?%24top=100&%24expand=thumbnails%2Clenses%2Ctags&select=*%2Cocr%2CwebDavUrl%2CsharepointIds%2CisRestricted%2CcommentSettings%2CspecialFolder%2CcontainingDrivePolicyScenarioViewpoint&ump=1&authKey=!AJb604PdiCpWoO4";
      const url = "https://api.onedrive.com/v1.0/drives/4a0b0fb0fad83a44/items/4A0B0FB0FAD83A44!392346/children?%24top=100&%24expand=thumbnails%2Clenses%2Ctags&select=*%2Cocr%2CwebDavUrl%2CsharepointIds%2CisRestricted%2CcommentSettings%2CspecialFolder%2CcontainingDrivePolicyScenarioViewpoint&ump=1&authKey=!ACqlbXgjhxL0rww";

      const response = await fetch(url, {
        method: "get",
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'accept': "application/json"
        },
      });

      const result = await response.json();
      setPhotos(result.value.map((photo: { [x: string]: any; id: any; }) => { return { url: photo['@content.downloadUrl'], id: photo.id } }));
    };

    loadPhotos();
  }, []);

  const handleClickOpen = (photo: { url: string; id: string; }) => {
    setOpen(true);
    setSelectedImg(photo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.photos}>
      <h1>{t("photos-title")}</h1>
      <Grid container spacing={3}>
        {photos.map((photo, index) => (
          <Grid item xs={6} sm={3} md={2} lg={1} key={photo.id}>
            <img
              src={photo.url}
              alt={`img${index}`}
              onClick={() => handleClickOpen(photo)}
              style={{ width: '100%', cursor: 'pointer' }}
            />
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <img src={selectedImg?.url} alt="selected" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </div>
  );
}

export default Photos;