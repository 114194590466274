import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { ParallaxBanner } from "react-scroll-parallax";
import styles from "./Banner.module.scss";

function Banner() {
  const bannerWidth = "600px";
  const { t } = useTranslation();
  return (
    <div className={styles.bannerParent}>
      <ParallaxBanner
        className={styles.banner}
        layers={[
          {
            image: "./img/home1.jpg",
            amount: -0.5,
            props: { className: styles.parallaxBanner },
          },
        ]}
        style={{
          height: bannerWidth,
        }}
      ></ParallaxBanner>
      <div className={styles.SaveTheDate}>
        <p>
          Save the Date <br />
          {t("date-title")}
        </p>
        <p className={styles.DeyvisonVanessa}>Vanessa e Deyvison</p>
      </div>
    </div>
  );
}
export default Banner;
