import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, Snackbar, SnackbarCloseReason, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WallMessage } from "../../models/wallMessage";
import { saveMessage } from "../../services/wallService";
import Frame from "../frame/Frame";
import styles from "./Wall.module.scss";

function Wall() {
  const { t } = useTranslation();

  const snackbarRef = React.createRef();

  const [values, setValues] = useState<WallMessage | null>(null);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (prop: keyof WallMessage) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...(values as WallMessage), [prop]: event.target.value });
  };

  const handleClose = (event: any, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={styles.Wall}>
      <Frame color="light-pink" className={styles.Frame}>
        <h1>{t("message-title")}</h1>
        <form className={styles.WallForm}>
          <TextField required className={styles.WallInput} value={values?.name} id="name" label={t("name")} onChange={handleChange("name")} />
          <TextField required className={styles.WallInput} value={values?.message} id="message" label={t("message")} onChange={handleChange("message")} multiline rows={4} />
          {showProgress && <CircularProgress />}
          <Snackbar className={styles.snackbar} ref={snackbarRef} open={open} autoHideDuration={6000} onClose={handleClose} message={t("success-wall")} />
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              try {
                setShowProgress(true);
                await saveMessage(values as WallMessage);
                setValues({ id: "", message: "", name: "" });
                setShowProgress(false);
                setOpen(true);
              } catch (ex) {
                console.log(ex);
              }
            }}
          >
            {t("sendMessage")}
          </Button>
        </form>
      </Frame>
    </div>
  );
}

export default Wall;
