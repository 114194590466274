import { newGuid } from "../helper/helper";
import { WallMessage } from "../models/wallMessage";

const url = (process.env.REACT_APP_API_URL ?? "") + "/Wall";
async function saveMessage(wallMessage: WallMessage): Promise<void> {

  if (!wallMessage.id) {
    wallMessage.id = newGuid();
  }

  await fetch(url, {
    method: "post",
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'accept': "application/json"
    },
    body: JSON.stringify(wallMessage)
  });
}


export { saveMessage };