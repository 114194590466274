import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/useWindowSize";
import styles from "./Navbar.module.scss";

function Navbar() {
  const { t, i18n } = useTranslation();
  const [showBar, setShowBar] = useState(false);
  const [width, height] = useWindowSize();

  const hamburguerMenu = (
    <a className={styles.hamburguerMenu} onClick={() => setShowBar(!showBar)}>
      Deyvison &amp; Vanessa<i className={`${styles.icon} fa fa-bars`}></i>
    </a>
  );
  const menu = (
    <ul>
      <li>
        <a href="#">{t("home")}</a>
      </li>
      <li>
        <a href="#marriage">{t("marriage")}</a>
      </li>
      <li>
        <a href="#ourstory">{t("ourstory")}</a>
      </li>
      <li>
        <a href="#Rsvp">{t("RSVP")}</a>
      </li>
      <li>
        <a href="#photos">{t("photos-title")}</a>
      </li>
      <li>
        <a className={styles.flag} onClick={() => i18n.changeLanguage(i18n.language == "ptBR" ? "en" : "ptBR")}>
          <img src={i18n.language == "ptBR" ? "./img/canada.svg" : "./img/brazil.svg"} alt="brazil" width={"18px"} height={"18px"} />
          &nbsp;&nbsp;{i18n.language == "ptBR" ? "English please" : "Português por favor"}
        </a>
      </li>
    </ul>
  );

  return (
    <nav className={styles.navbar}>
      {width < 700 && hamburguerMenu}
      {(showBar || width > 700) && menu}
    </nav>
  );
}

export default Navbar;
