import React from "react";
import styles from "./Frame.module.scss";

function Frame(props: { color: "red" | "orange" | "yellow" | "brown" | "dark-brown" | "rose" | "rose2" | "rose3" | "pink" | "light-pink"; className?: string; children: JSX.Element[] | JSX.Element }) {
  return <div className={`${styles.frame} ${getClass(props.color)} ${props.className}`}>{props.children}</div>;
}

function getClass(color: "red" | "orange" | "yellow" | "brown" | "dark-brown" | "rose" | "rose2" | "rose3" | "pink" | "light-pink") {
  switch (color) {
    case "red":
      return styles.frameRed;
    case "yellow":
      return styles.frameYellow;
    case "rose":
      return styles.frameRose;
    case "rose2":
      return styles.frameRose2;
    case "rose3":
      return styles.frameRose3;
    case "pink":
      return styles.framePink;
    case "light-pink":
      return styles.frameLightPink;
    default:
      break;
  }
}

export default Frame;
