import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import styles from "./Ourstory.module.scss";
import Frame from "../frame/Frame";

function Ourstory() {
  const images = Array(19)
    .fill(0)
    .map((i, indx) => {
      return { source: `./img/carrousel/img${indx + 1}.JPG` };
    });

  const { t } = useTranslation();
  return (
    <div id="ourstory">
      <Frame color="pink" className={styles.frame}>
        <p>Estamos unidos há 7 anos e chegou o momento de reafirmar o nosso amor perante de Deus!</p>
        <div className={styles.carrousel}>
          <Carousel stopOnHover={true} showStatus={false} showThumbs={false} autoPlay={true} transitionTime={200} infiniteLoop={true}>
            {images.map((i) => {
              return (
                <div key={i.source} className={styles.images}>
                  <img src={i.source} />
                </div>
              );
            })}
          </Carousel>
        </div>
        <p>Ficaremos muito felizes em compartilhar este momento especial com vocês!</p>
      </Frame>
    </div>
  );
}

export default Ourstory;
