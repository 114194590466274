import React, { useState } from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import "./App.css";
import Marriage from "./components/marriage/Marriage";
import Navbar from "./components/navbar/Navbar";
import Ourstory from "./components/ourstory/Ourstory";
import Banner from "./components/banner/Banner";
import "./i18n";
import Rsvp from "./components/rsvp/Rsvp";
import Countdown from "./components/countdown/Countdown";
import Wall from "./components/wall/Wall";
import Gifts from "./components/gifts/Gifts";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Photos from "./components/photos/Photos";

function App() {
  // const { t } = useTranslation();

  // const dialogRef = React.createRef();
  // const [open, setOpen] = useState(true);
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div className="App">
      <Navbar />
      <Banner />
      <Countdown />
      <Marriage />
      <Ourstory />
      <Wall />
      <Photos />
    </div>
  );
}

export default App;
