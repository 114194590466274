import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "home": "Home",
      "ourstory": "Our story",
      "marriage": "The marriage",
      "local": "Where",
      "where": "Where",
      "when": "When",
      "place": "Igreja madre de deus",
      "ceremony": "Ceremony",
      "date-title": "Saturday, March 26nd, 2022",
      "date": "March 26nd, 2022",
      "time-ceremony-title": "07:30 p.m.",
      "time-reception-title": "09:00 p.m.",
      "countdown": "Countdown",
      "days": "Days",
      "hours": "Hours",
      "minutes": "Minutes",
      "seconds": "Seconds",
      "Rsvp": "Rsvp",
      "name": "Name",
      "isGoing": "Are you going?",
      "yes": "yes",
      "no": "no",
      "email": "E-mail",
      "phoneNumber": "Phonenumber",
      "confirm": "Confirm",
      "message-title": "Wedding Wishes",
      "message": "Message",
      "sendMessage": "Send",
      "success-wall": "Message sent, Thank you",
      "success-rsvp": "Presence confirmed, we will wait for you",
      "gift": "Gift",
      "covid-alert": "Warning",
      "covid-alert-message": "Guys, as we know we are in a pandemic, we had the difficult task of limiting the amount of guests we would like at our party, and we have people from the risk group at the event, we want everyone to have fun safely, we know that we have several friends and family with children, but with our little ones it is always more difficult to maintain stricter distancing and hygiene measures, and due to the current moment, the lack of security and space and number of guests, unfortunately we had to restrict the entry of children at the reception of the marriage! Thank you for understanding!",
      "drink": "What are you going to drink?",
      "photos-title": "Photos"
    }
  },
  fr: {
    translation: {
      "home": "Accueil",
      "ourstory": "Notre estoire",
      "marriage": "Le marriage",
      "local": "Lieu",
      "where": "Ou",
      "when": "Quand",
      "place": "Igreja madre de deus",
      "ceremony": "Cerimonia",
      "date-title": "Samedi, 26 de março, 2022",
      "date": "26 mars 2022",
      "time-ceremony-title": "19:30 hrs",
      "time-reception-title": "21:00 hrs"
    }
  },
  ptBR: {
    translation: {
      "home": "Início",
      "ourstory": "Nossa historia",
      "marriage": "O Casamento",
      "local": "Local",
      "where": "Onde",
      "when": "Quando",
      "place": "Igreja madre de deus",
      "ceremony": "Cerimônia",
      "reception": "Recepção",
      "date-title": "Sábado, 26 de março, 2022",
      "date": "26 de março de 2022",
      "time-ceremony-title": "19:30 hrs",
      "time-reception-title": "21:00 hrs",
      "countdown": "Contagem Regressiva",
      "days": "Dias",
      "hours": "Horas",
      "minutes": "Minutos",
      "seconds": "Segundos",
      "Rsvp": "Confirme sua presença",
      "name": "Nome",
      "isGoing": "Você irá ao evento?",
      "yes": "sim",
      "no": "não",
      "email": "E-mail",
      "phoneNumber": "Telefone",
      "confirm": "Confirmar",
      "message-title": "Deixe seu recado",
      "message": "Messagem",
      "sendMessage": "Enviar",
      "gifts-title": "Lista de Presentes",
      "success-wall": "Recado enviado, Obrigado",
      "success-rsvp": "Presença confirmada, esperamos por você",
      "success-guest": "Presença confirmada, esperamos por você",
      "gift": "Presentear",
      "leave-gift-message-title": "Deixe uma mensagem para os noivos",
      "leave-gift-message-content": "",
      "covid-alert": "Atenção",
      "covid-alert-message": "Pessoal como sabemos estamos em uma pandemia, tivemos a difícil tarefa de limitar a quantidade de convidados que gostaríamos na nossa festa, e temos pessoas do grupo de risco no evento, queremos que todos se divirtam em segurança, sabemos que temos vários amigos e familiares com crianças, mas com os nossos pequenos é sempre mais difícil manter medidas de distanciamento e de higiene mais duras, e em razão do atual momento, da falta de segurança e espaço e número de convidados, infelizmente tivemos que restringir a entrada de crianças na recepção do casamento! Agradecemos a compreensão!",
      "close": "Fechar",
      "drink": "O que você gostaria de beber?",
      "sparklingWine": "Espumante",
      "whisky": "Whisky",
      "licor": "Licor",
      "beer": "Chopp",
      "noAlcohol": "Não vou beber",
      "drink-info": "Drinks também estarão disponiveis como Vodka e Gin",
      "black-tie": "BLACK TIE",
      "dress-code": "Tipo de traje/Dress code",
      "warning-dress": "Branco e Marsala são cores usadas pelos noivos e seu time, evite usá-las!",
      "photos-title": "Fotos"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ptBR", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;